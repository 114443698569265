var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "training-records-table-component-wrapper" },
    [
      _vm.showTitleBar
        ? _c("div", { staticClass: "top-title-bar" }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.tableObject.title) +
                  _vm._s(
                    _vm.formObjectExtra.operationType == 1
                      ? "现场培训记录表"
                      : "现场操作记录表"
                  )
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "table-search-group" },
        [
          _c(
            "el-form",
            {
              staticClass: "search-right",
              attrs: { model: _vm.requestParam, "label-width": "80px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "综合查询" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名/身份证号码" },
                    model: {
                      value: _vm.requestParam.searchVal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.requestParam,
                          "searchVal",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "requestParam.searchVal"
                    }
                  })
                ],
                1
              ),
              _vm.isHasOperationList()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "身份" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "popper-class": "records-item-down",
                            placeholder: "请选择身份"
                          },
                          model: {
                            value: _vm.requestParam.userClazz,
                            callback: function($$v) {
                              _vm.$set(_vm.requestParam, "userClazz", $$v)
                            },
                            expression: "requestParam.userClazz"
                          }
                        },
                        _vm._l(_vm.getUserIdentityList(), function(
                          item,
                          index
                        ) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isHasTrainList()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "结果" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "popper-class": "records-item-down"
                          },
                          model: {
                            value: _vm.requestParam.state,
                            callback: function($$v) {
                              _vm.$set(_vm.requestParam, "state", $$v)
                            },
                            expression: "requestParam.state"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "所有", value: null }
                          }),
                          _c("el-option", {
                            attrs: { label: "学习中", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "异常", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "正常", value: 2 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isHasOperationList()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "结果" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "popper-class": "records-item-down"
                          },
                          model: {
                            value: _vm.requestParam.resultType,
                            callback: function($$v) {
                              _vm.$set(_vm.requestParam, "resultType", $$v)
                            },
                            expression: "requestParam.resultType"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "所有", value: null }
                          }),
                          _c("el-option", {
                            attrs: { label: "正常", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "异常", value: 1 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isHasTrainList() || _vm.isHasOperationList()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "起止时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetimerange",
                          align: "center",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"]
                        },
                        on: { change: _vm.fetchStartEndTime },
                        model: {
                          value: _vm.formObjectExtra.startEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formObjectExtra, "startEndTime", $$v)
                          },
                          expression: "formObjectExtra.startEndTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { "label-width": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onQuerySearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "switch-bar" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "medium" },
              on: { change: _vm.monitorOperationType },
              model: {
                value: _vm.formObjectExtra.operationType,
                callback: function($$v) {
                  _vm.$set(_vm.formObjectExtra, "operationType", $$v)
                },
                expression: "formObjectExtra.operationType"
              }
            },
            [
              _vm.supportSignOut
                ? _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("可签退列表")
                  ])
                : _vm._e(),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v(
                  _vm._s(_vm.isOfflineMeeting ? "会议记录" : "培训记录") + " "
                )
              ]),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("操作记录")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "table-content-wrapper" }, [
        _vm.isHasSignOutList()
          ? _c(
              "div",
              { staticClass: "signout-record-wrapper table-list-wrapper" },
              [
                _c(
                  "el-table",
                  {
                    key: "signOut-list",
                    attrs: { data: _vm.tableObject.dataList, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        width: "50",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.requestParam.currentPage - 1) *
                                            _vm.tableObject.responsePageSize +
                                            scope.$index +
                                            1
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2254702817
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "姓名",
                        prop: "realName",
                        width: "100px",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "身份证号码",
                        prop: "cardNo",
                        width: "180px",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "签到时间", "min-width": "80px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.startTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2018045356
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.isOfflineMeeting
                          ? "开始会议时间"
                          : "开始学习时间",
                        "min-width": "80px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.clockTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3413060036
                      )
                    }),
                    _vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "duration",
                            label: "本次完成时长",
                            formatter: _vm.formatterTotalHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.isNormalData(scope.row)
                                          ? "el-table-row-normal"
                                          : "el-table-row-throw"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterFinishedValidHours(
                                                scope.row
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            568239766
                          )
                        })
                      : _vm._e(),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "totalHours",
                            label: "要求学时",
                            formatter: _vm.formatterTotalHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "el-table-row-normal" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterTotalHours(scope.row)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            180853373
                          )
                        })
                      : _vm._e(),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "finishedHours",
                            label: "本次学习时长",
                            formatter: _vm.formatterFinishedHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "el-table-row-normal" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterFinishedHours(
                                                scope.row
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2518121453
                          )
                        })
                      : _vm._e(),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "finishedTotalHours",
                            label: "已完成总学时",
                            formatter: _vm.formatterFinishedTotalHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "el-table-row-normal" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterFinishedTotalHours(
                                                scope.row
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2377143567
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.isHasTrainList()
          ? _c(
              "div",
              { staticClass: "train-record-wrapper table-list-wrapper" },
              [
                _c(
                  "el-table",
                  {
                    key: "train-list",
                    attrs: { data: _vm.tableObject.dataList, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        width: "50",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.requestParam.currentPage - 1) *
                                            _vm.tableObject.responsePageSize +
                                            scope.$index +
                                            1
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2828984812
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "姓名", width: "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.realName
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1476673513
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "身份证号", width: "180px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.cardNo
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        350525185
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "身份", width: "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.identityName
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3378259845
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "签到时间", "min-width": "80px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.startTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3974633537
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.isOfflineMeeting
                          ? "开始会议时间"
                          : "开始学习时间",
                        "min-width": "80px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.clockTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1197734761
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "签退时间", "min-width": "80px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.endTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4044982958
                      )
                    }),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "totalHours",
                            label: "要求时长",
                            formatter: _vm.formatterTotalHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.isNormalData(scope.row)
                                          ? "el-table-row-normal"
                                          : "el-table-row-throw"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterTotalHours(scope.row)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            314839280
                          )
                        })
                      : _vm._e(),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "finishedTotalHours",
                            label: "已完成时长",
                            formatter: _vm.formatterFinishedTotalHours
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.isNormalData(scope.row)
                                          ? "el-table-row-normal"
                                          : "el-table-row-throw"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatterFinishedTotalHours(
                                                scope.row
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1775033282
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "finishedHours",
                        label: _vm.isOfflineMeeting
                          ? "本次会议时长"
                          : "本次学习时长"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterFinishedHours(scope.row)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4211358816
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "finishedValidHours",
                        label: "本次完成时长"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterFinishedValidHours(
                                            scope.row
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        568239766
                      )
                    }),
                    _vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: { prop: "sourceType", label: "场景" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.isNormalData(scope.row)
                                          ? "el-table-row-normal"
                                          : "el-table-row-throw"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("signSourceFilter")(
                                                scope.row.sourceType
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2236501116
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "状态", width: "80px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterStateStr(scope.row)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2139834915
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "validateDescription",
                        label: "抽验情况",
                        formatter: _vm.formatterItemValueStr
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " 应抽验" +
                                        _vm._s(
                                          _vm.formatterItemValueNumber(
                                            scope.row,
                                            scope.$index,
                                            scope.row.validateCount
                                          )
                                        ) +
                                        "次 "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " 实际抽验" +
                                        _vm._s(
                                          _vm.formatterItemValueNumber(
                                            scope.row,
                                            scope.$index,
                                            scope.row.realValidateCount
                                          )
                                        ) +
                                        "次 "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3047479233
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "stateDescription",
                        label: "结果",
                        formatter: _vm.formatterItemValueStr
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.isNormalData(scope.row)
                                      ? "el-table-row-normal"
                                      : "el-table-row-throw"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.stateDescription
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        751706437
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.isHasOperationList()
          ? _c(
              "div",
              { staticClass: "operation-record-wrapper table-list-wrapper" },
              [
                _c(
                  "el-table",
                  {
                    key: "operation-list",
                    attrs: { data: _vm.tableObject.dataList, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        width: "50",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.requestParam.currentPage - 1) *
                                            _vm.tableObject.responsePageSize +
                                            scope.$index +
                                            1
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2254702817
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "姓名", width: "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.realName
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3282255428
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "身份证号", width: "180px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "el-table-row-normal" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatterItemValueStr(
                                            scope.row,
                                            scope.$index,
                                            scope.row.cardNo
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        992574188
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "identityName",
                        label: "身份",
                        width: "100px",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "signSource",
                        label: "方式",
                        formatter: _vm.formatterSignSource
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "signType",
                        label: "操作类型",
                        formatter: _vm.formatterSignType
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "validateTime",
                        label: "操作时间",
                        "min-width": "80px",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "clockTime",
                        label: _vm.isOfflineMeeting
                          ? "会议开始时间"
                          : "开始学习时间",
                        "min-width": "80px",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    !_vm.isOfflineMeeting
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "validateStartTime",
                            label: "抽验提示时间",
                            "min-width": "80px",
                            formatter: _vm.formatterItemValueStr
                          }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "resultType",
                        label: "结果",
                        formatter: _vm.formatterResultType
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "stateDescription",
                        label: "备注",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operationAddress",
                        label: "操作地址",
                        formatter: _vm.formatterItemValueStr
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "faceUrl", label: "凭证" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.compareFaceUrl &&
                                scope.row.compareFaceUrl.length > 0
                                  ? _c("el-image", {
                                      staticClass: "face-img",
                                      attrs: {
                                        src: scope.row.compareFaceUrl,
                                        "preview-src-list": [
                                          scope.row.compareFaceUrl
                                        ]
                                      }
                                    })
                                  : _c("span", [_vm._v("无")])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1420651222
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.tableObject.dataList && _vm.tableObject.dataList.length > 0
        ? _c("el-pagination", {
            staticClass: "source-pagination",
            attrs: {
              background: "",
              "current-page": _vm.tableObject.responseCurrentPage,
              "page-sizes": _vm.Constants.pageSizes,
              "page-size": _vm.tableObject.responsePageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableObject.responseTotal
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }