import hoursApi from "@api/hours/hoursApi";
import Constants from "@util/constants/Constants";
import TrainFormatterHelper from "../../../util/formatter/TrainFormatterHelper";
import TibiMatomoManger from "../../../util/manager/TibiMatomoManger";
import TrackConstants from "../../../util/constants/TrackConstants";
import { getCurrentSystemType } from "../../../util/user/UserManger";
import { SYSTEM_TYPE } from "../../../util/constants/EnumConstants";

/**
 * 培训记录与操作记录 table 组件
 */
export default {
    name: "TraningRecordsTable",
    props: {
        /**
         * 请求参数
         */
        propsStudentListParams: {
            type: Object,
            default: function() {
                return {};
            },
            required: false,
        },
        /**
         * 是否支持可签退列表
         */
        supportSignOut: {
            type: Boolean,
            default: false,
        },
        /**
         * 是否显示标题的bar
         */
        showTitleBar: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            tableObject: {
                dataList: [], // 表格数据
                responsePageSize: 10,
                responseTotal: 0,
                title: "",
            },
            // 其他数据
            formObjectExtra: {
                operationType: 1, // 1 培训记录  2 操作记录  可签退列表 0
                identityList: [], // 身份列表
                timer: null, // 任务刷新数据
                timeCount: 30, // 30 s 刷新一次
                startEndTime: [], // 开始时间与结束时间
            },

            // 请求参数
            requestParam: {
                currentPage: 1, // 当前页
                pageSize: Constants.pageSizeDefault, // 每页展示的条数
                offlineRecordId: null, // 现场学习培训记录ID
                planId: null,
                userId: this.$store.getters.userId, // 用户id
                searchVal: "", // 综合查询
                userClazz: null, // 身份code
                resultType: null, // 0 正常 1 异常  所有
                state: null, //状态 0:学习中，1：异常，2：正常
                signType: 40, // 40:培训记录，50：操作记录  签到类型（0:待签到，10：已签到，20：抽验，30：签退）, 抽验, 签退需要状态0等待中, 2已成功的用户
                startTime: "",
                endTime: "",
            },
        };
    },
    mounted() {
        /**
         * 定时任务
         */
        const that = this;
        this.formObjectExtra.timer = setInterval(() => {
            that.handleCurrentChange(that.requestParam.currentPage);
        }, 1000 * that.formObjectExtra.timeCount);
    },
    computed: {
        /**
         * 是否是现场会议
         */
        isOfflineMeeting() {
            return getCurrentSystemType() == SYSTEM_TYPE.MEETING;
        },
    },
    methods: {
        /**
         * 点击查询按钮
         */
        onQuerySearch() {
            let name = TrackConstants.eventNameKeys.train_record_table_menu_query_sign_out;
            if (this.formObjectExtra.operationType == 1) {
                name = TrackConstants.eventNameKeys.train_record_table_menu_query_train_record;
            } else if (this.formObjectExtra.operationType == 2) {
                name = TrackConstants.eventNameKeys.train_record_table_menu_query_operation_record;
            }
            TibiMatomoManger.sendTrainRecordTableTrackEvent(name);
            this.handleCurrentChange(1);
        },
        onQuerySearchDataList() {
            this.handleCurrentChange(1);
        },

        /**
         * 监听操作操作类型
         */
        monitorOperationType(val) {
            this.formObjectExtra.operationType = val;
            let name = TrackConstants.eventNameKeys.train_record_table_menu_list_sign_out;
            if (this.formObjectExtra.operationType == 1) {
                name = TrackConstants.eventNameKeys.train_record_table_menu_list_train_record;
                this.requestParam.signType = 40;
                this.requestParam.resultType = null;
            } else if (this.formObjectExtra.operationType == 2) {
                name = TrackConstants.eventNameKeys.train_record_table_menu_list_operation_record;
                this.requestParam.signType = 50;
                this.requestParam.state = null;
            } else {
                this.requestParam.signType = null;
                this.requestParam.state = null;
                this.requestParam.resultType = null;
            }
            TibiMatomoManger.sendTrainRecordTableTrackEvent(name);
            this.formObjectExtra.startEndTime = [];
            this.requestParam.startTime = "";
            this.requestParam.endTime = "";
            this.requestParam.searchVal = "";
            this.onQuerySearchDataList();
        },

        /**
         * 获取起止时间
         */
        fetchStartEndTime(val) {
            if (val != null && val.length > 1) {
                this.requestParam.startTime = val[0];
                this.requestParam.endTime = val[1];
            } else {
                this.requestParam.startTime = "";
                this.requestParam.endTime = "";
            }
        },

        /**
         * 监听分页size
         */
        handleSizeChange(val) {
            this.requestParam.pageSize = val;
            this.loadNetWorkDataList();
        },

        /**
         * 监听分页page
         */
        handleCurrentChange(val) {
            this.requestParam.currentPage = val;
            this.loadNetWorkDataList();
        },

        /**
         * 加载数据
         */
        loadNetWorkDataList() {
            if (this.formObjectExtra.operationType == 1) {
                // 培训记录
                this.loadTrainRecordData();
            } else if (this.formObjectExtra.operationType == 2) {
                // 操作记录
                this.loadTrainRecordData();
            } else {
                // 可签退列表
                this.loadSupportSigOutListData();
            }
        },

        /**
         * 签退列表数据请求
         */
        loadSupportSigOutListData() {
            const that = this;
            if (!this.requestParam.offlineRecordId || this.requestParam.offlineRecordId.length == 0) {
                console.log("缺少offineRecordId");
                return;
            }
            const param = this.requestParam;
            hoursApi.signinList(param).then((response) => {
                if (response.success && response.data) {
                    const data = response.data;
                    that.tableObject.dataList = data;
                    that.tableObject.responseTotalPage = response.totalPage;
                    that.tableObject.responseTotal = response.total;
                    that.tableObject.responseCurrentPage = response.currentPage;
                    that.tableObject.responsePageSize = response.pageSize;
                } else {
                    that.tableObject.dataList = [];
                    // if (response.code == 102) {

                    //     HudGlobal.showWarningWithMessage(response.description);
                    // } else {
                    //     HudGlobal.showErrorWithMessage(response.description);
                    // }
                }
            });
        },

        /**
         * 加载培训记录列表
         */
        loadTrainRecordData() {
            const that = this;
            if (!this.requestParam.offlineRecordId || this.requestParam.offlineRecordId.length == 0) {
                console.log("缺少offineRecordId");
                return;
            }
            const param = this.requestParam;
            hoursApi.getOfflineUserList(param).then((response) => {
                if (response.success && response.data) {
                    const data = response.data;
                    that.tableObject.dataList = data;

                    if (!that.tableObject.title || that.tableObject.title.length == 0) {
                        if (data && data.length > 0) {
                            const firstItem = data[0];
                            that.tableObject.title = firstItem.planName;
                        }
                    }
                    that.tableObject.responseTotalPage = response.totalPage;
                    that.tableObject.responseTotal = response.total;
                    that.tableObject.responseCurrentPage = response.currentPage;
                    that.tableObject.responsePageSize = response.pageSize;
                } else {
                    that.tableObject.dataList = [];
                    // fbz 2020-8-19 不需要提示
                    // if (response.code == 102) {

                    //     HudGlobal.showWarningWithMessage(response.description);
                    // } else {
                    //     HudGlobal.showErrorWithMessage(response.description);
                    // }
                }
            });
        },
        // ================================================UI======================================//
        /**
         * 获取身份列表
         */
        getUserIdentityList() {
            const identityList = [
                {
                    value: "",
                    label: "所有",
                },
                {
                    value: 101,
                    label: "普通员工",
                },
                {
                    value: 102,
                    label: "培训学员",
                },
                {
                    value: 103,
                    label: "培训监督员",
                },
            ];
            return identityList;
        },
        /**
         * 数据是否正常
         */
        isNormalData(item) {
            if (this.isHasTrainList()) {
                // 结果（0:正常，1:异常）
                const result = item.resultType == 0 ? true : false;
                return result;
            } else {
                return true;
            }
        },
        /**
         * 是否是培训记录列表
         */
        isHasTrainList() {
            return this.formObjectExtra.operationType == 1;
        },
        /**
         * 是否是操作列表
         */
        isHasOperationList() {
            return this.formObjectExtra.operationType == 2;
        },
        /**
         * 是否可以签退列表
         */
        isHasSignOutList() {
            if (this.supportSignOut) {
                return this.formObjectExtra.operationType == 0;
            } else {
                return false;
            }
        },
        /**
         * 字符串处理
         * */
        formatterItemValueStr(row, column, cellValue) {
            if (cellValue == null || cellValue.length == 0) {
                return "-";
            } else {
                return cellValue;
            }
        },
        formatterItemValueNumber(row, column, cellValue) {
            if (!cellValue) {
                return 0;
            } else {
                return cellValue;
            }
        },

        /**
         * 处理要求学时
         */
        formatterTotalHours(item) {
            if (item.totalHours == null || item.totalHours == undefined) {
                return "0秒";
            }
            const time = TrainFormatterHelper.missToMinuteStr(item.totalHours);
            return `${time}`;
        },
        /**
         * 处理本次学习时长(分钟)
         */
        formatterFinishedHours(item) {
            if (item.finishedHours == null || item.finishedHours == undefined) {
                return "0秒";
            }
            const time = TrainFormatterHelper.missToMinuteStr(item.finishedHours);
            return `${time}`;
        },
        /**
         * 本次完成的有效学习时长(分钟)
         */
        formatterFinishedValidHours(item) {
            if (!!item.finishedValidHours == null || item.finishedValidHours == undefined) {
                return "0秒";
            }
            const time = TrainFormatterHelper.missToMinuteStr(item.finishedValidHours);
            return `${time}`;
        },

        /**
         * 处理已完成时长(分钟)
         */
        formatterFinishedTotalHours(item) {
            if (item.finishedTotalHours == null || item.finishedTotalHours == undefined) {
                return "0秒";
            }
            const time = TrainFormatterHelper.missToMinuteStr(item.finishedTotalHours);
            return `${time}`;
        },
        /**
         * 结果正常异常
         */
        formatterResultType(item) {
            if (item.resultType == null || item.resultType == undefined) {
                return "-";
            }
            // 结果（0:正常，1:异常）
            const str = item.resultType == 0 ? "正常" : "异常";
            return str;
        },
        /**
         * 处理学时状态 学时有效无效状态（11：无效 ，12：待审核，13：审核不通过（作弊），14：审核通过)暂时做只有无效，有效,11.14")
         */
        formatterHoursState(item) {
            if (!item || !item.hoursState) {
                return "-";
            }
            // 学时有效无效状态（11：无效 ，12：待审核，13：审核不通过（作弊），14：审核通过)暂时做只有无效，有效,11.14")
            switch (item.hoursState) {
                case 11: {
                    return "无效";
                }
                case 14: {
                    return "有效";
                }
                default: {
                    return "未知";
                }
            }
        },

        /**
         * 判断状态 lzb 20220624 新增状态判断 参考管理端
         */
        formatterStateStr(item) {
            if (!item) {
                return "-";
            }
            switch (item.state) {
                case 0: {
                    return "进行中";
                }
                case 14: {
                    return "有效";
                }
                default: {
                    return "无效";
                }
            }
        },

        /**
         * 处理方式
         */
        formatterSignSource(item) {
            if (!item || !item.signSource) {
                return "-";
            }
            const str = TrainFormatterHelper.formatterSourceType(item.signSource);
            return str;
        },
        /**
         * 处理操作类型
         */
        formatterSignType(item) {
            if (!item || !item.signType) {
                return "-";
            }
            // 签到类型（10：签到，20：抽验，30：签退 40: 上课验证）
            switch (item.signType) {
                case 10: {
                    return "签到";
                }
                case 20: {
                    return "抽验";
                }
                case 30: {
                    return "签退";
                }
                case 40: {
                    return "上课验证";
                }
                default: {
                    return "未知";
                }
            }
        },
    },
    /**
     * 销毁定时器时间
     */
    destroyed() {
        clearInterval(this.formObjectExtra.timer); // 清除定时器
        this.formObjectExtra.timer = null;
    },
    watch: {
        propsStudentListParams: {
            immediate: true,
            deep: true,
            handler(value) {
                const { offlineRecordId, classroomId, planId, planName } = value;
                this.requestParam.offlineRecordId = offlineRecordId;
                this.requestParam.classroomId = classroomId;
                this.requestParam.planId = planId;
                this.tableObject.title = planName;

                if (offlineRecordId && offlineRecordId.length > 0) {
                    // 刷新数据
                    this.onQuerySearchDataList();
                }
            },
        },
        supportSignOut: {
            immediate: true,
            deep: true,
            handler(val) {
                this.supportSignOut = val;
                if (val) {
                    this.formObjectExtra.operationType = 0;
                }
            },
        },
    },
    filters: {
        formatterSecond(second) {
            if (second == null || second == undefined) {
                return "-";
            }
            const time = TrainFormatterHelper.missToMinuteStr(second);
            return `${time}`;
        },
    },
};
